import { ApiAxios } from './api.config';

const RESOURCE = '/__admin/payments';

export default {
  async listPayments(limit, page) {
    return await ApiAxios().get(RESOURCE, {
      params: {
        limit,
        page
      }
    });
  },
  async findPayments(pan_1, pan_2) {
    return await ApiAxios().post(RESOURCE, {
      pan_1,
      pan_2
    });
  },
  async refundPayment(payment_id) {
    return await ApiAxios().post(`${RESOURCE}/${payment_id}/refund`);
  },

  async chargerbackPayment(payment_id) {
    return await ApiAxios().post(`${RESOURCE}/${payment_id}/chargerback`);
  }
};
