import { computed } from 'vue';

export default function dateTransformer(dateTotransform) {
  const setNumber = n => (`${n}`.length == 1 ? `0${n}` : `${n}`);
  const dateTransformed = computed(() => {
    const date = new Date(dateTotransform);
    return `${date.getFullYear()}/${setNumber(date.getMonth() + 1)}/${setNumber(date.getDate())} ${setNumber(date.getHours())}:${setNumber(
      date.getMinutes()
    )}`;
  });

  return {
    dateTransformed
  };
}

export function dateTransformerClassic(dateToTransform) {
  const setNumber = n => (`${n}`.length == 1 ? `0${n}` : `${n}`);
  const date = new Date(dateToTransform);
  const dateTransformed = () => {
    return `${date.getFullYear()}/${setNumber(date.getMonth() + 1)}/${setNumber(date.getDate())} ${setNumber(date.getHours())}:${setNumber(
      date.getMinutes()
    )}`;
  };
  return {
    dateTransformed: dateTransformed()
  };
}
