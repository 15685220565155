<template lang="pug">
td
  .indicator(:class="payment.payment_status")
td {{ dateTransformed }}
td {{ payment.payment_country }}
td.text-xs {{ payment.payment_type }}
td.text-xs {{ payment.payment_status }}
td {{ amount }}
td
  routerLink(
    :to="{ name: 'ProfileUser', params: { user_id: payment.user_id } }",
    target="_blank"
  )
    .ml-auto.flex.justify-center.items-center.w-40.bg-blue-700.rounded-md.py-1.cursor-pointer
      svgIcon.mr-2.-ml-2(type="mdi", :path="mdiAccount", :size="18")
      .text-sm Show profile
</template>
<script>
import { toRefs } from "vue";
import { mdiAccount } from "@mdi/js";
import dateTransformer from "@/composables/dateTransformer";

export default {
  name: "rowListPayment",
  props: {
    payment: Object,
  },
  data: () => ({
    mdiAccount,
  }),

  setup(props) {
    const { payment } = toRefs(props);
    const { dateTransformed } = dateTransformer(payment.value.created_at);
    return {
      dateTransformed,
    };
  },

  computed: {
    amount() {
      const price = this.payment.payment_amount / 100;
      return new Intl.NumberFormat("es-ES", {
        style: "currency",
        currency: "EUR",
      }).format(price);
    },
  },
};
</script>
<style lang="scss" scoped>
</style>