<template lang="pug">
.container
  baseHeading List Payments
  .text-sm.-mt-4.mb-4 Total results: <b>{{ total_payments }}</b>
  .flex.justify-end.mb-4
    baseInputText(
      typeInput="number",
      labelText="6 first digits",
      v-model="pan_1"
    )
    baseInputText.ml-2(
      typeInput="number",
      labelText="4 last digits",
      v-model="pan_2"
    )
    baseButton.ml-2.mt-5(@click="findPayments") Search

  .border.rounded-md.mb-4
    table.table-auto.w-full.text-left
      thead.border-b
        tr
          th.w-6
          th Date
          th Country
          th Type
          th Status
          th Amount
          th.w-10 Options
      tbody
        tr(v-for="item in list_payments", :key="item._id")
          RowListPayment(:payment="item")
  .flex.justify-end
    .pagination
      ul
        li(v-for="pagination_page in pagination", :key="pagination_page.page")
          routerLink(
            :to="`/payments?page=${pagination_page.page}&limit=${limit}`",
            :class="{ active: pagination_page.page == page }"
          ) {{ pagination_page.page }}
</template>
<script>
import PaymentsRepository from "@/api/payments.repository";
import RowListPayment from "@/components/payments/rowListPayment";
export default {
  name: "ListPayments",
  components: {
    RowListPayment,
  },
  props: {
    page: {
      type: Number,
      default: 1,
    },
    limit: {
      type: Number,
      default: 100,
    },
  },
  data: () => ({
    total_pages: 0,
    total_payments: 0,
    list_payments: [],
    pan_1: null,
    pan_2: null,
  }),
  created() {
    this.getPayments();
  },
  watch: {
    page() {
      this.getPayments();
    },
    limit() {
      this.getPayments();
    },
  },
  methods: {
    getPayments() {
      PaymentsRepository.listPayments(this.limit, this.page).then(
        ({ data }) => {
          this.total_pages = data.total_pages;
          this.list_payments = data.list_payments;
          this.total_payments = data.total_results;
        }
      );
    },
    findPayments() {
      this.total_pages = 0;
      this.total_payments = 0;
      this.list_payments = [];
      PaymentsRepository.findPayments(this.pan_1, this.pan_2).then(
        ({ data }) => {
          this.total_pages = 1;
          this.list_payments = data;
          this.total_payments = data.length;
        }
      );
    },
  },
  computed: {
    pagination() {
      const pages = [];

      if (this.page >= 1 && this.total_pages >= 1) {
        for (let index = 3; index > 0; index--) {
          if (this.page - index > 1) {
            pages.push({
              page: this.page - index,
            });
          }
        }
      }

      if (this.page >= 1 && this.total_pages >= 1) {
        for (let index = 0; index < 5; index++) {
          if (this.page + index <= this.total_pages) {
            pages.push({
              page: this.page + index,
            });
          }
        }
      }

      if (pages[0]?.page > 1) {
        pages.unshift({
          page: 1,
        });
      }
      return pages;
    },
  },
};
</script>
<style lang="scss" scoped>
table {
  thead {
    th {
      @apply p-2;
    }
  }
  :deep()tbody {
    td {
      @apply p-2;
    }
    tr:nth-child(odd) {
      background-color: rgb(36, 45, 59);
    }
  }
}

.pagination ul {
  margin: 0;
  padding-left: 0;
}

.pagination li {
  list-style: none;
  float: left;

  & + li {
    margin-left: 4px;
  }
}

.pagination a {
  border: 1px solid transparent;
  float: left;
  position: relative;
  text-decoration: none;
  @apply w-8 h-8 flex items-center justify-center rounded-md font-semibold text-gray-400 bg-gray-600 cursor-pointer;
}

.pagination a:hover {
  @apply bg-gray-500;
}

.pagination a.active,
.pagination a.active:focus,
.pagination a.active:hover {
  @apply bg-gray-500 text-white;
  cursor: default;
}

.pagination .ellipsis a,
.pagination .disabled a,
.pagination .disabled a:focus,
.pagination .disabled a:hover {
  cursor: not-allowed;
}

.pagination .disabled a,
.pagination .disabled a:focus,
.pagination .disabled a:hover {
  cursor: not-allowed;
  opacity: 0.4;
}
</style>